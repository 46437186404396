import React, { useState, useEffect } from 'react';
import { DataGrid,GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,GridOverlay  } from '@mui/x-data-grid';
  import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdTune } from "react-icons/md";
  import { CircularProgress } from '@mui/material';
import { basic } from '../../../themes/basic';

const DataGridStyle = (props) => {
  const spaceBetween = 2;
  const [pageSize, setPageSize] = useState(props.pagesize);
  const [loading, setLoading] = useState(true); 


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <CircularProgress style={{ color: basic.greenColor }} size={54} />
      </GridOverlay>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily:basic.fontFamily,
          fontSize: basic.contentFontSize,
          padding: '8px 16px', // Adding some padding for spacing
        }}
      >
        {/* This div is for centering the quick filter */}
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          
        </div>
        
        {/* This div is for placing buttons to the right */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <GridToolbarQuickFilter
            sx={{
              padding: 0,
              background: basic.tableBgColor,
              fontFamily:basic.fontFamily,
              fontSize: basic.buttonFontSize,
              borderRadius: "8px",
              width: "200px", // Adjust the width as needed
              height: "35px",
              marginRight:"8px",
              "& .MuiSvgIcon-root": {
                margin: "10px",
                marginTop:"8px",
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
              "& .MuiButtonBase-root": {
                display: "none",
              },
              "& .MuiInputBase-input": {
                fontSize: basic.contentFontSize,
                fontWeight: 300,
                paddingTop:"1px"
              },
              "& .MuiInput-underline:hover:before": {
                display: "none",
              },
            }}
          />
          <GridToolbarFilterButton sx={{ fontSize:basic.buttonFontSize,marginRight: spaceBetween, fontFamily:basic.fontFamily,
            "& .MuiDataGrid-panelWrapper": {
              fontFamily:basic.fontFamily,
            }
           }} />
          {props.export && (
            <GridToolbarExport
              sx={{ fontFamily:basic.fontFamily, fontSize:basic.contentFontSize, marginRight: spaceBetween,                
                  '& .MuiDataGrid-menu':{
                    color:"red"
                  },
              }}
              csvOptions={{ fileName: 'products-data', allColumns: true }}
              printOptions={{ disableToolbarButton: true }}

            />
          )}
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <DataGrid
      {...props}
      disableSelectionOnClick
      components={{
        ColumnSortedAscendingIcon: MdKeyboardArrowDown,
        ColumnSortedDescendingIcon: MdKeyboardArrowUp,
        Toolbar: CustomToolbar,
        OpenFilterButtonIcon: MdTune,
        LoadingOverlay: CustomLoadingOverlay,
      }}
      componentsProps={{
        columnMenu: {
          sx: {
            backgroundColor: "white", 
            display: "grid",
          },
        },
        filterPanel: {
          sx: {
            '& .MuiTypography-root': {
              fontFamily: basic.fontFamily,
              fontSize: basic.contentFontSize,                
            },
            '& .MuiInputBase-root': {              
              fontFamily: basic.fontFamily,
              fontSize: basic.contentFontSize,  
            },
            '& .MuiInputLabel-root': {              
              fontFamily: basic.fontFamily,
              fontSize: basic.contentFontSize,  
              color:basic.colorBlack,
              fontWeight:"bold",
            },
            "& svg":{
              fontSize: basic.iconsFontSize,  
            },
          },
        }
      }}
      loading={loading}
      pageSize={pageSize}
      // hideFooterPagination
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      sx={{
        //backgroundColor: basic.backColor,
        // backgroundColor: "red",
        color: "black",
        border: "none",
        marginBottom: 1,
        marginTop: 1,
        fontFamily:basic.fontFamily,
        "& .MuiDataGrid-virtualScroller": {
          borderTop: "1px solid #90bb41",
          marginTop:"35px !important",
        },

        "& .MuiDataGrid-cell:focus ": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },

        /* header styles starts */
        "& .MuiDataGrid-main": {
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          backgroundColor: basic.tableBgColor,
        },
        "& .MuiDataGrid-columnHeaders": {
          //backgroundColor: basic.colorWhite,
          color: basic.colorBlack,          
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          minHeight:"auto !important",
          maxHeight:"none !important",
          lineHeight:"normal !imporant",
          height:"35px",
        },

        /* menu */
        "& .MuiDataGrid-menuIconButton": {
          opacity: "inherit !important",
          color: basic.colorBlack,
        },

        /* columns separator */
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },

        "&  .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root": {
          color: basic.colorBlack,

          "&.Mui-checked": {
            color: basic.greenColor,
          },
        },

        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 800,
          fontSize: basic.contentFontSize,
        },
        /* body checkbox color */
        "& .MuiDataGrid-cellCheckbox .MuiCheckbox-colorPrimary": {
          color: "black",
          //while checked
          "&.Mui-checked": {
            color: basic.greenColor,
          },
        },

        "& .MuiDataGrid-cellContent": {
          fontWeight: 400,
          fontSize: basic.labelFontSize,
        },
        /* sort menu */
        "& .MuiDataGrid-sortIcon": {
          color: basic.colorBlack,
          opacity: "inherit !important",
          visibility: "visible !important",
          position: "relative",
        },

        /*scroll bar */
        "& ::-webkit-scrollbar": {
          height: 5,
        },

        /*footer styles */
        "& .MuiDataGrid-footerContainer": {
          //backgroundColor: basic.backColor,
          borderTop: 0,
        },
        "& .MuiDataGrid-toolbarContainer": {
          //backgroundColor: basic.backColor,
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        },
        "& .MuiButton-root": {
          color: "black",
          fontSize:basic.contentFontSize,
          textTransform:"capitalize"
        },
        "& .MuiDataGrid-footerContainer .MuiTablePagination-selectLabel":{
          marginTop:"13px"
        },
        "& .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows":{
          marginTop:"13px"
        },
        "& .MuiFormControlLabel-label, .MuiDataGrid-cellContent": {
          fontSize:basic.contentFontSize,
        },
        '& .MuiMenuItem-root': {
              fontFamily: basic.fontFamily,
              fontSize: basic.contentFontSize, 
        },
        '& .MuiDataGrid-overlay':{
            top:"-13px",
            fontSize:basic.contentFontSize,
            backgroundColor:basic.tableBgColor,
        }
      }} 


    />
  );
};

DataGridStyle.defaultProps = {
  export: true,
  pagesize: 10,
};

export default DataGridStyle; 