import React, { useState, useEffect } from 'react';
import {  Box, IconButton, Grid, TextField, FormControlLabel, CircularProgress, List, ListItem  } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import PageHeading from '../page_heading';
import BackButton from '../common/back_button';
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup";
import { basic, textFieldAndShrinkStyle } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';

const EditCourse = () => {
  const { courseId } = useParams(); 
  const [__currentCourseId, __setCurrentCourseId] = useState(null);
  const [__productData, __setProductData] = useState(null);
  const [__courseName, __setCourseName] = useState(null);
  const [__courseDescription, __setCourseDescription] = useState(null);
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__successMsg, __setSuccessMsg] = useState(null);
  const [__messageStatus, __setMessageStatus] = useState(null);
  const [__isVisible, __setIsVisible] = useState(false);
  const [__courseExistsValue, __setCourseExistsValue] = useState(false); 
  const [__checkMessage, __setCheckMessage] = useState(''); 
  const [__suggestions, __setSuggestions] = useState([]); 
  const [__fetchingSuggestions, __setFetchingSuggestions] = useState(false);
  const [__selecteProductId, __setSelecteProductId] = useState([]);
  const [__productCourseData, __setProductCourseData] = useState([]);
  const [__selectedVariantId, __setSelectedVariantId] = useState(null);
  const [__clickFetchSuggestionList, __setClickFetchSuggestionList] = useState(false);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success"); // state success or error

  const __navigate = useNavigate() 

  const __handleOpen = () => __setModalOpen(true);
  const __handleClose = () => __setModalOpen(false);

  const __checkCourseNameExists = async (aCourseName) => {    
   const _getProductId = sessionStorage.getItem("_selectedProductID");
    try {

      const _getSelectedCourse = await axios.get(`/product/${_getProductId}`);
      const _getProductAssocationData = _getSelectedCourse.data.data.productAssociation;
      let _variantIndex = _getProductAssocationData.findIndex(
        assoc => assoc.variantId._id.toString() === __selectedVariantId
      );

      const _getCurrentVarintCourses = _getProductAssocationData[_variantIndex]?.courses;
      const _productCourseData = _getCurrentVarintCourses?.map((aItem) => ({
        courseName:aItem.courseId.courseName,
        courseDescription:aItem.courseId.courseDescription,
        isVisible:aItem.isVisible ? 'Inactive' : 'Active',
        id: aItem.courseId._id,
      }));

      //filter the current edit course id
      const _matchingWithNameAndId = _productCourseData.filter(course => 
         course.id !== courseId
      );

       
      const _foundCourseName = _matchingWithNameAndId.find(course => course.courseName === aCourseName);
      
      if(_foundCourseName){
      __setCourseExistsValue(true);
      __setCheckMessage("This course name is already in use.");
      __setSuggestions([]);  
      }else{
        if (!__clickFetchSuggestionList) {
          __fetchCourseSuggestions(aCourseName);
        }
      __setCourseExistsValue(false);
      __setCheckMessage("Course name is available.");
      }
    } catch (error) {
      console.error("Error checking product name:", error);
    }
  };

  const __fetchCourseSuggestions = async (aInput) => {
    try {
      __setFetchingSuggestions(true);
      const _courseResponse = await axios.post("/course/search", { query: aInput });
      console.log("__fetchCourseSuggestions",_courseResponse);
      __setSuggestions(_courseResponse.data.data); 
      __setFetchingSuggestions(false);
    } catch (error) {
      console.error("Error fetching course suggestions:", error);
      __setFetchingSuggestions(false);
    }
  };

  const __handleCourseNameChange = (e) => {
    const _courseName = e.target.value;
    console.log("__handleCourseNameChange",_courseName);
    __setCourseName(_courseName);
    if (_courseName.length > 0) {
      __setClickFetchSuggestionList(false);
      __checkCourseNameExists(_courseName);
    } else {
      __setSuggestions([]);
    }
  };

  const __handleSelectCourse = (courseName) => {
    __setCourseName(courseName); // Set selected course name
    __setClickFetchSuggestionList(true);
    __setSuggestions([]); // Clear suggestions after selection
    __checkCourseNameExists(courseName);
  };

  const __updateCourseFunc = async (e) => {
    e.preventDefault();
    __setModalOpen(false);
    __setLoading(true);

    let _courseData = {
      courseName: __courseName,
      courseDescription: __courseDescription,
      //isVisible: isVisible
    };
    
    const _updateCourseData = await axios.put(`/course/update/${courseId}`, _courseData);
    
    if(_updateCourseData.data.status=="success"){
      __setLoading(false);
      __triggerSnackbar("The course has been updated successfully.","success"); 
     // __setMessageStatus("success");
      //__setSuccessMsg("The course has been updated successfully.");
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to update course.","error"); 
      //__setMessageStatus("failed");
      //__setSuccessMsg("Failed to update course.");
    }
  };

  const __handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  useEffect(() => {
    const __fetchProduct = async () => {
      try {
        console.log(courseId);
        const _response = await axios.get(`/course/${courseId}`);
        console.log("fetchProduct",_response.data.data)
        //__setProductData(_response.data.data);
        __setCourseName(_response.data.data.courseName);
        __setCourseDescription(_response.data.data.courseDescription);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    __fetchProduct();
    __setCurrentCourseId(courseId);

    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");
    if(_getSelectedProductId){
      __setSelecteProductId(_getSelectedProductId);
    }

    const _getSelectedVariantId = sessionStorage.getItem("_selectedVariantID");
    if(_getSelectedVariantId){
      __setSelectedVariantId(_getSelectedVariantId);
    }
    // Fetch courses from API
  }, [courseId]);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
   
      <PageHeading title="Edit Course" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="course"
                label="Course Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%" }}
                value={__courseName || ""} 
                onChange={__handleCourseNameChange}
                sx={textFieldAndShrinkStyle}
                error={__courseName === ""}
                helperText={__courseName === "" ? "Course name is required." : ""}
              />

              
            {__courseName && <div style={{ fontSize:basic.contentFontSize,color: __courseExistsValue ? 'red' : 'green' }}>{__checkMessage}</div>}


            {/* Suggestions list */}
            {__fetchingSuggestions && !__clickFetchSuggestionList ? (
              <CircularProgress style={{ color: basic.greenColor }} size={24} />
            ) : (
              __suggestions.length > 0 && !__clickFetchSuggestionList && (
                <List style={{ display:"block", backgroundColor:"#fff"}}>
                  {__suggestions.map((courseItem) => (
                    <ListItem
                      key={courseItem._id}
                      onClick={() => __handleSelectCourse(courseItem.courseName)}
                      style={{ fontSize:basic.contentFontSize,color: basic.colorWhite, cursor:"pointer", backgroundColor:basic.logoGreyColor, borderRadius:"5px", marginRight:"5px", display:"inline-block", width:"auto", marginBottom:"5px", paddingTop:"5px"}}
                      title='Select Course'
                      sx={{
                        "& li.MuiListItem-root:last-child": {
                          borderBottom: "0px",
                        },
                      }}  
                    >
                      {courseItem.courseName}
                    </ListItem>
                  ))}
                </List>
              )
            )}

              <TextField
                id="description"
                label="Course Description"
                value={__courseDescription || ""}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px" }}
                onChange={(e) => __setCourseDescription(e.target.value)}
                sx={textFieldAndShrinkStyle}
                error={__courseDescription === ""}
                helperText={
                  __courseDescription === ""
                    ? "Course description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
              
              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
                <CustomButton2
                onClick={__handleOpen}
                disabled={
                  !(__courseName && __courseName.length > 0 && __courseDescription && __courseDescription.length > 0) ||
                  __courseExistsValue // Disable button if product exists
                }
                //disabled={!(__courseName && __courseName.length > 0 && productDescription && productDescription.length > 0)}
                aria-label="Save"
                >
                  <Save fontSize={basic.headingFontSize} style={{ marginTop:-1 }}/>
                  <div style={{ marginLeft: 5 }}>Save</div>
                </CustomButton2>
                <BackButton backToPage="/adminDashboard/course" aButtonText="Cancel" />
              </Grid>
            
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={__modalOpen}
        onClose={__handleClose}
        onConfirm={__updateCourseFunc}
        title="Alert"
        description="Are you sure you want to update?"
        loading={__loading}
        successMsg={__successMsg}
        successStatus={__messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { __navigate("/adminDashboard/course") }}
        onFinalButtonText="Go to Courses"
      />

      <LoadingOverlay loading={__loading} />
      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

    </div>
  );
};

export default EditCourse;
